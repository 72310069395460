import ReactDOM from 'react-dom'

// Broadcast
import Pusher from 'pusher-js'
import Echo from 'laravel-echo'

// Axios
import axios from 'axios'
import {Chart, registerables} from 'chart.js'
import {QueryClient, QueryClientProvider} from 'react-query'
import {ReactQueryDevtools} from 'react-query/devtools'

// Apps
import {MetronicI18nProvider} from './_metronic/i18n/Metronici18n'
/**
 * TIP: Replace this style import with dark styles to enable dark mode
 *
 * import './_metronic/assets/sass/style.dark.scss'
 *
 * TIP: Replace this style import with rtl styles to enable rtl mode
 *
 * import './_metronic/assets/css/style.rtl.css'
 **/
import './_metronic/assets/sass/style.scss'
import './_metronic/assets/sass/style.react.scss'
import {AppRoutes} from './app/routing/AppRoutes'
import {AuthProvider, getAuth, setupAxios} from './app/modules/auth'

/**
 * Creates `axios-mock-adapter` instance for provided `axios` instance, add
 * basic Metronic mocks and returns it.
 *
 * @see https://github.com/ctimmerm/axios-mock-adapter
 */


/**
 * Inject Metronic interceptors for axios.
 *
 * @see https://github.com/axios/axios#interceptors
 */
setupAxios(axios)


//Make Pusher Available
// @ts-ignore
window.Pusher = Pusher


const API_URL = process.env.REACT_APP_API_URL
export const BROADCAST_AUTH_URL = `${API_URL}/broadcasting/auth`

const auth = getAuth()

const api_token = auth && auth.api_token ? auth.api_token : ''

const config = {
    headers: {
        Authorization: `Bearer ${api_token}`,
        Accept: "application/json"
    }
};

// @ts-ignore
const options = {
    broadcaster: "pusher",
    key: process.env.REACT_APP_MIX_PUSHER_APP_KEY,
    cluster: process.env.REACT_APP_MIX_PUSHER_APP_CLUSTER,
    forceTLS: true,
    encrypted: false,

    authEndpoint: BROADCAST_AUTH_URL,
    // As I'm using JWT tokens, I need to manually set up the headers.
    auth: config,

    //OR

    // authorizer: (channel: any, options: any) => {
    //     return {
    //         authorize: (socketId: any, callback: any) => {
    //             axios.post(BROADCAST_AUTH_URL, {
    //                 socket_id: socketId,
    //                 channel_name: channel.name
    //             }, config)
    //                 .then(response => {
    //                     callback(false, response.data);
    //                 })
    //                 .catch(error => {
    //                     callback(true, error);
    //                 });
    //         }
    //     };
    // },

};

// @ts-ignore
window.Echo = new Echo(options);

/*

let courseId = 1;

// @ts-ignore
window.Echo.private(`courses.${courseId}`).listen("NewCourseCreated", (data: any) => {

    console.log(data);

});

*/

Chart.register(...registerables)

const queryClient = new QueryClient()

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <MetronicI18nProvider>
      <AuthProvider>
        <AppRoutes />
      </AuthProvider>
    </MetronicI18nProvider>
    <ReactQueryDevtools initialIsOpen={false} />
  </QueryClientProvider>,
  document.getElementById('root')
)
